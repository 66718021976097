<template>
  <div class="block">
    <h1 class="title">Samochód</h1>
    <h1 class="subtitle">Podaj informacje o swoim samochodzie</h1>
    <div class="container">
      <div class="columns is-multiline">
        <!-- Car Brand -->
        <div class="column is-half">
          <b-field label="Marka">
            <b-select
              placeholder="Wybierz markę"
              v-model="form.carBrand"
              :disabled="carBrandFieldProperties.disabled"
              :loading="carBrandFieldProperties.loading"
              @input="onCarBrandSelect"
              expanded
            >
              <option
                v-for="carBrand in carBrands"
                :value="carBrand.id_car_make"
                :key="carBrand.id_car_make"
              >
                {{ carBrand.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-half"></div>

        <!-- Car Model -->
        <div class="column is-half">
          <b-field label="Model">
            <b-select
              placeholder="Wybierz model"
              v-model="form.carModel"
              :disabled="carModelFieldProperties.disabled"
              :loading="carModelFieldProperties.loading"
              @input="onCarModelSelect"
              expanded
            >
              <option
                v-for="carModel in carModels"
                :value="carModel.id_car_model"
                :key="carModel.id_car_model"
              >
                {{ carModel.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-half"></div>

        <!-- Car Generation -->
        <div class="column is-half">
          <b-field label="Generacja">
            <b-select
              placeholder="Wybierz generację"
              v-model="form.carGen"
              :disabled="carGenFieldProperties.disabled"
              :loading="carGenFieldProperties.loading"
              @input="onCarGenerationSelect"
              expanded
            >
              <option
                v-for="carGen in carGenerations"
                :value="carGen.id_car_generation"
                :key="carGen.id_car_generation"
              >
                {{ carGen.name }}, {{ carGen.year_begin }} -
                {{ carGen.year_end }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-half"></div>

        <!-- Car Serie -->
        <div class="column is-half">
          <b-field label="Seria">
            <b-select
              placeholder="Wybierz serie"
              v-model="form.carSerie"
              :disabled="carSerieFieldProperties.disabled"
              :loading="carSerieFieldProperties.loading"
              @input="onCarSerieSelect"
              expanded
            >
              <option
                v-for="carSerie in carSeries"
                :key="carSerie.id_car_serie"
                :value="carSerie.id_car_serie"
              >
                {{ carSerie.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-half"></div>

        <!-- Car Engine -->
        <div class="column is-half">
          <b-field label="Silnik">
            <b-select
              placeholder="Wybierz wersje"
              v-model="form.carEngine"
              :disabled="carEngineFieldProperties.disabled"
              :loading="carEngineFieldProperties.loading"
              expanded
            >
              <option
                v-for="carEngine in carEngines"
                :key="carEngine.id_car_trim"
                :value="carEngine.id_car_trim"
              >
                {{ carEngine.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-half"></div>
      </div>

      <div class="columns is-multiline">
        <div class="column is-full">
          <b-field grouped label="Lakier">
            <b-field>
              <type-picker
                v-model="form.paintType"
                title="Rodzaj"
                :types="paintTypes"
              ></type-picker>
            </b-field>
            <b-field>
              <type-picker
                v-model="form.paintColor"
                title="Kolor"
                :types="paintColors"
              ></type-picker>
            </b-field>
          </b-field>
        </div>
        <div class="column is-full">
          <b-field grouped label="Tapicerka">
            <b-field>
              <type-picker
                v-model="form.upholsteryType"
                title="Rodzaj"
                :types="upholsteryTypes"
              ></type-picker>
            </b-field>
            <b-field>
              <type-picker
                v-model="form.upholsteryColor"
                title="Kolor"
                :types="upholsteryColors"
              ></type-picker>
            </b-field>
          </b-field>
        </div>
        <div class="column is-full">
          <type-picker
            v-model="form.rims"
            title="Felgi"
            :types="rimsTypes"
          ></type-picker>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-button
            type="is-primary"
            :disabled="isLoading"
            :loading="isLoading"
            @click="createCar"
            >Dodaj samochód</b-button
          >
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <b-message
            v-model="showMessage"
            :class="messageProperties.type"
            :title="messageProperties.title"
          >
            {{ messageProperties.message }}
            <div v-if="isSuccess">
              <router-link :to="{ name: 'Cars' }">
                Przejdź do zarządzania pojazdami
              </router-link>
            </div>
          </b-message>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import TypePicker from "@/components/car-data/TypePicker";

export default {
  components: {
    "type-picker": TypePicker,
  },

  data() {
    return {
      isLoading: false,
      showMessage: false,
      isSuccess: false,

      messageProperties: {
        type: "",
        title: "",
        message: "",
      },

      carBrandFieldProperties: {
        disabled: true,
        loading: false,
      },

      carModelFieldProperties: {
        disabled: true,
        loading: false,
      },

      carGenFieldProperties: {
        disabled: true,
        loading: false,
      },

      carSerieFieldProperties: {
        disabled: true,
        loading: false,
      },

      carEngineFieldProperties: {
        disabled: true,
        loading: false,
      },

      form: {
        carBrand: "",
        carModel: "",
        carGen: "",
        carSerie: "",
        carEngine: "",
        paintType: "",
        paintColor: "",
        upholsteryType: "",
        upholsteryColor: "",
        rims: "",
      },
    };
  },

  created() {
    this.carBrandFieldProperties.disabled = true;
    this.carBrandFieldProperties.loading = true;

    this.$store
      .dispatch("carData/getCarData")
      .then((responses) => {
        /* 
                        responses to tablica obiektów response dla requestów pobierających rodzaje marek, tapicerki, lakieru i felg.
                        jeżeli chociaż jeden request się nie powiódł (status !== 200) to wyświetl komunikat o błędzie
                        */
        const hasErrors = responses.some((response) => {
          return response.status !== 200;
        });

        if (hasErrors) {
          //console.log("Nie udało się pobrać wszystkich danych!");
          return;
        }

        this.carBrandFieldProperties.disabled = false;
        this.carBrandFieldProperties.loading = false;
      })
      .catch((error) => {
        console.error(error);
        this.carBrandFieldProperties.loading = false;
      });
  },

  methods: {
    /*
            fieldPropertiesKey
            actionToDispatch
            actionDataKey
        */
    async onSelectFieldChange(options) {
      const { fieldPropertiesKey, actionToDispatch, actionDataKey } = options;

      if (fieldPropertiesKey.toLowerCase() === "carBrand".toLowerCase()) {
        for (const field in this.form) {
          if (field.toLowerCase() === "carBrand".toLowerCase()) {
            continue;
          }

          this.form[field] = "";
        }
      }

      this[fieldPropertiesKey].loading = true;

      if (!this.form[actionDataKey]) {
        console.error("Bład");
        this[fieldPropertiesKey].loading = false;
        return;
      }

      try {
        const result = await this.$store.dispatch(
          actionToDispatch,
          this.form[actionDataKey]
        );

        if (result.status !== 200) {
          //console.log("Nie udalo sie zaladowac modeli");
          this[fieldPropertiesKey].loading = false;

          return;
        }

        this[fieldPropertiesKey].disabled = false;
      } catch (err) {
        console.error(
          `Błąd pobierania modeli samochodowych dla marki o id=${this.form.carBrand}`
        );
      }

      this[fieldPropertiesKey].loading = false;
    },

    async onCarBrandSelect() {
      const options = {
        fieldPropertiesKey: "carModelFieldProperties",
        actionToDispatch: "carData/getCarModelsByBrandId",
        actionDataKey: "carBrand",
      };

      this.onSelectFieldChange(options);
    },

    async onCarModelSelect() {
      const options = {
        fieldPropertiesKey: "carGenFieldProperties",
        actionToDispatch: "carData/getCarGenerationsByModel",
        actionDataKey: "carModel",
      };

      this.onSelectFieldChange(options);
    },

    async onCarGenerationSelect() {
      const options = {
        fieldPropertiesKey: "carSerieFieldProperties",
        actionToDispatch: "carData/getCarSeriesByGen",
        actionDataKey: "carGen",
      };
      this.onSelectFieldChange(options);
    },

    async onCarSerieSelect() {
      const options = {
        fieldPropertiesKey: "carEngineFieldProperties",
        actionToDispatch: "carData/getCarEnginesBySerie",
        actionDataKey: "carSerie",
      };
      this.onSelectFieldChange(options);
    },

    async createCar() {
      this.showMessage = false;
      this.isLoading = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        //console.log("Nie podano wszystkich danych");
        this.messageProperties = {
          type: "is-danger",
          title: "Niepowodzenie",
          message: "Nie podano wszystkich danych",
        };
        this.showMessage = true;
        this.isLoading = false;
        return;
      }

      const carDataPayload = {};

      carDataPayload.car_upholstery_color_id = this.form.upholsteryColor;
      carDataPayload.car_upholstery_type_id = this.form.upholsteryType;
      carDataPayload.car_paint_color_id = this.form.paintColor;
      carDataPayload.car_paint_type_id = this.form.paintType;
      carDataPayload.car_rims_type_id = this.form.rims;
      carDataPayload.id_car_serie = this.form.carSerie;
      carDataPayload.id_car_trim = this.form.carEngine;
      carDataPayload.description = "OPIS";

      try {
        const result = await this.$store.dispatch(
          "clientCars/createCar",
          carDataPayload
        );

        if (result.status !== 201) {
          throw result;
        }

        this.messageProperties = {
          type: "is-primary",
          title: "Sukces",
          message: "Utworzono samochód!",
        };

        this.isSuccess = true;
        this.showMessage = true;
      } catch (err) {
        this.messageProperties = {
          type: "is-danger",
          title: "Niepowodzenie",
          message:
            "Nie udało się utworzyć samochodu. Spróbuj ponownie później lub skontaktuj się z administratorem systemu.",
        };

        this.showMessage = true;

        console.error(err);
      }

      this.isLoading = false;
    },
  },

  computed: {
    carBrands() {
      return this.$store.getters["carData/carBrands"];
    },

    carModels() {
      return this.$store.getters["carData/carModels"];
    },

    carGenerations() {
      return this.$store.getters["carData/carGenerations"];
    },

    carSeries() {
      return this.$store.getters["carData/carSeries"];
    },

    carEngines() {
      return this.$store.getters["carData/carEngines"];
    },

    upholsteryTypes() {
      return this.$store.getters["carData/upholsteryTypes"];
    },

    upholsteryColors() {
      return this.$store.getters["carData/upholsteryColors"];
    },

    paintTypes() {
      return this.$store.getters["carData/paintTypes"];
    },

    paintColors() {
      return this.$store.getters["carData/paintColors"];
    },

    rimsTypes() {
      return this.$store.getters["carData/rimsTypes"];
    },
  },

  validations: {
    form: {
      carBrand: {
        required,
      },

      carModel: {
        required,
      },

      carGen: {
        required,
      },

      carSerie: {
        required,
      },

      carEngine: {
        required,
      },

      paintType: {
        required,
      },

      paintColor: {
        required,
      },

      upholsteryType: {
        required,
      },

      upholsteryColor: {
        required,
      },

      rims: {
        required,
      },
    },
  },
};
</script>