<template>
    <div class="container">
        <div class="columns">
            <div class="column is-half is-offset-one-quarter box">
                <car-creator></car-creator>
            </div>
        </div>
    </div>
</template>
<script>
import CarCreator from '@/components/car-data/CarCreator'

export default {
    components: {
        "car-creator": CarCreator
    }
}
</script>