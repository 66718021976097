<template>
    <div>
        <b-field :label="title">
            <b-select v-model="typeSelected" @input="onSelectChange">
                <option v-for="(type, index) in types" :key="index" :value="type.id">
                    {{ type.name }}
                </option>
            </b-select>
        </b-field>
    </div>
</template>

<script>
export default {
    props: ['title', 'types', 'value'],

    data() {
        return {
            typeSelected: "",
        }
    },

    methods: {
        onSelectChange() {
            this.$emit('input', this.typeSelected)
        }
    }
}
</script>